import { Button } from '@/components/button';
import { TrLink } from '@/core/i18n';
import { LANG } from '@/core/i18n/src/page-lang';
import { EVENT_NAME, EVENT_TRACK } from '@/core/sensorsdata';
import { memo } from 'react';
import css from 'styled-jsx/css';
import { ROUTE_PATH_KEY } from '../constants';
import { useLogin } from '../hooks/useLogin';

export const LoginButton = memo((props: { shouldDisableBtn: boolean; onLoginSuccess: () => void }) => {
  const { onLoginSuccess, shouldDisableBtn } = props;
  const [handleLogin] = useLogin(onLoginSuccess);
  const onForgetBtnClick = () => {
    EVENT_TRACK(EVENT_NAME.ForgotPasswordClick);
  };
  const onLoginBtnClick = () => {
    if (shouldDisableBtn) return;
    handleLogin?.();
  };
  return (
    <div className='login-btn'>
      <TrLink href={`/${ROUTE_PATH_KEY.FORGET}`} className='bottom-link' onClick={onForgetBtnClick}>
        {LANG('忘记密码')}？
      </TrLink>
      <Button
        type='primary'
        className={shouldDisableBtn ? 'disabled' : ''}
        height={44}
        style={{ width: '100%' }}
        onClick={onLoginBtnClick}
      >
        {LANG('登陆')}
      </Button>
      <style jsx>{styles}</style>
    </div>
  );
});
const styles = css`
  .login-btn {
    :global(.bottom-link) {
      font-size: 12px;
      font-weight: 400;
      color: var(--skin-main-font-color);
      margin-bottom: 44px;
      display: inline-block;
      &:hover {
        color: var(--skin-hover-brand-font-color);
      }
    }
  }
`;
