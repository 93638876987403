import { VipPasswordInput } from '@/components/basic-input/vip-pwd-input';
import { InputPhone } from '../components/input-phone';
import { InputInvitationCode } from '../components/invitation-code';
import { useBtnStatus } from '../hooks/useBtnStatus';
import { TAB_TYPE } from '../types';
import { RegisterFooter } from './register-footer';
import { RegisterNextBtn } from './register-next';

export const PhoneRegister = ({ handleRegisterUser }: { handleRegisterUser?: () => void }) => {
  const [shouldDisableBtn] = useBtnStatus(TAB_TYPE.PHONE_REGISTER);
  return (
    <>
      <InputPhone />
      <VipPasswordInput />
      <InputInvitationCode />
      <RegisterNextBtn
        shouldDisableBtn={shouldDisableBtn}
        handleRegisterUser={() => {
          if (shouldDisableBtn) return;
          handleRegisterUser?.();
        }}
      />
      <RegisterFooter />
    </>
  );
};
