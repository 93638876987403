import { TrLink } from '@/core/i18n/src/components/tr-link';
import { LANG } from '@/core/i18n/src/page-lang';
import { clsx } from '@/core/utils/src/clsx';
import { MediaInfo } from '@/core/utils/src/media-info';
import css from 'styled-jsx/css';
import { ROUTE_PATH_KEY } from '../constants';

const LoginFooter = ({ showQrCodePanel }: { showQrCodePanel: boolean }) => {
  return (
    <div className={clsx('bottom-area', showQrCodePanel && 'bottom-qrcode')}>
      <div className='left-area'>
        <span className='bottom-tips'>
          {LANG('还不是BYDFI用户')}？
          <TrLink href={`/${ROUTE_PATH_KEY.REGISTER}`} className='bottom-link'>
            {LANG('立即注册')}
          </TrLink>
        </span>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};
export { LoginFooter };

const styles = css`
  .bottom-area {
    position: relative;
    display: flex;
    border-top: 1px solid var(--spec-border-level-2);
    padding-top: 16px;
    .left-area {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    align-items: center;

    .bottom-tips {
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      color: var(--spec-font-color-1);
      :global(.bottom-link) {
        color: var(--skin-main-font-color);
      }
      @media ${MediaInfo.mobile} {
        margin-top: 16px;
      }
    }
    @media ${MediaInfo.mobile} {
      align-items: flex-start;
      flex-direction: column;
      padding-top: 24px;
    }
  }
  .bottom-qrcode {
    justify-content: center;
    border-top: none;
    text-align: center;
    .left-area {
      text-align: center;
    }
  }
`;
