import { Button } from '@/components/button';
import { LANG } from '@/core/i18n/src/page-lang';
import { Account } from '@/core/shared/src/account';
import { MediaInfo } from '@/core/utils';
import { message } from '@/core/utils/src/message';
import css from 'styled-jsx/css';
import { store } from '../store';

export const ForgetButton = (props: any) => {
  const { shouldDisableBtn } = props;
  const handleEmailForget = async () => {
    if (shouldDisableBtn) {
      return;
    }
    const { phone, countryCode, email, curTab } = store;
    const newPhone = countryCode + (phone || '').replace(/^0*/, '');
    const account = curTab === 0 ? email : newPhone;
    try {
      const result = await Account.securityVerify.accountVerify(account);
      if (result?.code !== 200) {
        store.showForgetEntry = true;
        message.error(result?.message);
      } else {
        store.showForgetEntry = false;
        store.showGaVerify = result.data.verify_ga;
      }
    } catch (error: any) {
      message.error(error.message);
    }
  };
  return (
    <Button
      type='primary'
      style={{ width: '100%', padding: '14px 0' }}
      onClick={handleEmailForget}
      disabled={shouldDisableBtn}
      className='forget-btn'
    >
      {LANG('下一步')}
      <style jsx>{styles}</style>
    </Button>
  );
};
const styles = css`
  :global(.forget-btn) {
    @media ${MediaInfo.desktop} {
      margin-top: 54px;
    }
    @media ${MediaInfo.mobileOrTablet} {
      margin-top: 32px;
    }
  }
`;
