/** 验证码页面 */
import CommonIcon from '@/components/common-icon';
import { BottomModal, MobileModal } from '@/components/mobile-modal';
import { BasicModal } from '@/components/modal';
import { Desktop, DesktopOrTablet, Mobile, MobileOrTablet } from '@/components/responsive';
import { LANG, renderLangContent } from '@/core/i18n';
import { SENCE } from '@/core/shared';
import { LOCAL_KEY } from '@/core/store';
import { MediaInfo } from '@/core/utils';
import { useEffect, useState } from 'react';
import css from 'styled-jsx/css';
import { useSendCaptchaCode } from '../hooks/useSendCaptcha';
import { store } from '../store';
import { TAB_TYPE } from '../types';
import DigitCodeInput from './code-input';
import { RegisterButton } from './register-btn';

export default function VerifyRegisterCode({
  type,
  onBack2Register,
  countDown,
  localType,
}: {
  type: TAB_TYPE;
  onBack2Register: () => void;
  countDown: number;
  localType: LOCAL_KEY;
}) {
  const { countdown, getVerificationCode } = useSendCaptchaCode(localType, SENCE.REGISTER);
  const [localCountdown, setLocalCountdown] = useState(countDown);
  const [inputCodes, setInputCodes] = useState<string>('');
  const phone = store.phone;
  const email = store.email;
  const [isRegisteredFailed, setIsRegisteredFailed] = useState(false);
  useEffect(() => {
    if (countdown === 90) {
      setLocalCountdown(countDown);
    } else {
      setLocalCountdown(countdown);
    }
  }, [countDown, countdown]);
  const onResendCode = async () => {
    if (localCountdown === 0) {
      await getVerificationCode();
    }
  };
  const [showReceiveMsgModal, setShowReceiveMsgModal] = useState(false);
  const onShowReceiveMsgModal = () => {
    setShowReceiveMsgModal(true);
  };
  const onServiceLinkClick = (e: any) => {
    if (window.zE) {
      window.zE('messenger', 'show');
      window.zE('messenger', 'open');
      window.zE('messenger:on', 'close', () => {
        window.zE('messenger', 'hide');
      });
    }
  };
  const onInputCode = (code: string) => {
    setInputCodes(code);
    setIsRegisteredFailed(false);
  };
  const onRegisterFailed = () => {
    setIsRegisteredFailed(true);
  };
  const renderRulesModalContent = () => {
    return (
      <>
        <p className='title'>{LANG('请尝试以下操作：')}</p>
        <ul className='rule-list'>
          <li>· {LANG('确认邮件是否在垃圾箱中。')}</li>
          <li>· {LANG('确认邮件邮箱地址是否为 ellatestoiwe@biust.com。')}</li>
          <li>· {LANG('邮件可能存在发送延迟，请10分钟后重试。')}</li>
          <li>
            ·{' '}
            {renderLangContent(LANG('若问题仍未解决，请{contact}寻求帮助。'), {
              contact: (
                <a className='link' onClick={onServiceLinkClick}>
                  {LANG('联系客服')}
                </a>
              ),
            })}
          </li>
        </ul>
      </>
    );
  };
  return (
    <div className='verify-code-card'>
      <Desktop>
        <div className='back-btn' onClick={onBack2Register}>
          <CommonIcon name='common-prev-icon-0' size={12} />
          <span className='name'>{LANG('返回')}</span>
        </div>
      </Desktop>
      <MobileOrTablet>
        <h1 className='register-title'>{LANG('注册')}</h1>
      </MobileOrTablet>
      <h1 className='title'>{type === TAB_TYPE.EMAIL_REGISTER ? LANG('验证您的邮箱') : LANG('验证您的手机号')}</h1>
      <p className='tips'>
        {type === TAB_TYPE.EMAIL_REGISTER
          ? renderLangContent(LANG('请输入您电子邮箱地址{email}收到的6位驗證碼'), {
              email: <span className='value'>({email})</span>,
            })
          : renderLangContent(LANG('请输入您手机号{phone}收到的6位驗證碼'), {
              phone: <span className='value'>({phone})</span>,
            })}
      </p>
      <div className='code-container'>
        <DigitCodeInput onInputCode={onInputCode} isRegisteredFailed={isRegisteredFailed} />
      </div>
      {localCountdown >= 1 && localCountdown < 90 ? (
        <span className='resent-code' onClick={onResendCode}>
          {LANG('Resend {countDown}s', { countDown: localCountdown })}
        </span>
      ) : (
        <span className='resend-code' onClick={onResendCode}>
          {LANG('Resend code')}
        </span>
      )}
      <RegisterButton
        btnText={LANG('确认')}
        shouldDisableBtn={inputCodes.length !== 6}
        onRegisterFailed={onRegisterFailed}
        type={type === TAB_TYPE.EMAIL_REGISTER ? LOCAL_KEY.INPUT_REGISTER_EMAIL : LOCAL_KEY.INPUT_REGISTER_PHONE}
      />
      <p className='receive-msg' onClick={onShowReceiveMsgModal}>
        {LANG('Have not received the verification code?')}
      </p>
      {showReceiveMsgModal && (
        <>
          <DesktopOrTablet>
            <BasicModal
              cancelButtonProps={{ style: { display: 'none' } }}
              okText={LANG('确认')}
              open={showReceiveMsgModal}
              onCancel={() => setShowReceiveMsgModal(false)}
              onOk={() => setShowReceiveMsgModal(false)}
              title={LANG('未收到验证邮件？')}
            >
              {renderRulesModalContent()}
            </BasicModal>
          </DesktopOrTablet>
          <Mobile>
            <MobileModal visible={showReceiveMsgModal} onClose={() => setShowReceiveMsgModal(false)} type='bottom'>
              <BottomModal className='rules-mobile-modal' title={LANG('未收到验证邮件？')}>
                {renderRulesModalContent()}
              </BottomModal>
            </MobileModal>
          </Mobile>
        </>
      )}
      <style jsx>{styles}</style>
    </div>
  );
}
const styles = css`
  .verify-code-card {
    .back-btn {
      margin-bottom: 16px;
      cursor: pointer;
      .name {
        margin-left: 4px;
        color: var(--spec-font-color-2);
        font-size: 14px;
        font-weight: 500;
      }
    }
    .register-title {
      text-align: center;
      font-size: 28px;
      font-weight: 500;
      color: var(--spec-font-color-1);
      margin-bottom: 24px;
    }
    .title {
      margin-bottom: 16px;
      color: var(--spec-font-color-1);
      font-size: 24px;
      font-weight: 600;
      @media ${MediaInfo.mobileOrTablet} {
        font-size: 20px;
      }
    }
    .tips {
      color: var(--spec-font-color-2);
      font-size: 16px;
      @media ${MediaInfo.desktop} {
        margin-bottom: 24px;
      }
      @media ${MediaInfo.mobileOrTablet} {
        font-size: 16px;
        margin-bottom: 32px;
      }
      .value {
        color: var(--spec-font-color-1);
      }
    }
    .resend-code {
      color: var(--skin-main-font-color);
      cursor: pointer;
      margin-top: 16px;
      float: right;
      margin-bottom: 32px;
      display: inline-block;
    }
    .resent-code {
      color: var(--spec-font-color-2);
      margin-top: 16px;
      float: right;
      margin-bottom: 32px;
      display: inline-block;
    }
    :global(.confirm-btn) {
      height: 44px;
      width: 100%;
      margin-top: 24px;
    }
    .receive-msg {
      font-size: 14px;
      font-weight: 500;
      color: var(--skin-main-font-color);
      margin-top: 20px;
      cursor: pointer;
    }
    :global(.common-button.disabled) {
      background-color: var(--spec-background-color-4);
      color: var(--spec-font-color-2);
    }
  }
  @mixin basic-modal-ui {
    :global(.title) {
      font-size: 16px;
      margin-bottom: 16px;
      font-weight: 500;
      color: var(--spec-font-color-1);
    }
    :global(.rule-list) {
      border-radius: 4px;
      list-style: none;
      padding: 0;
      margin: 0;
      background-color: var(--spec-background-color-3);
      padding: 16px;
      :global(li) {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 500;
        color: var(--spec-font-color-1);
      }
      :global(.link) {
        color: var(--skin-main-font-color);
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  :global(.basic-modal .ant-modal-body) {
    @include basic-modal-ui;
  }
  :global(.rules-mobile-modal) {
    @include basic-modal-ui;
    :global(.title) {
      margin-top: 16px;
    }
    :global(.bottom) {
      display: none;
    }
  }
`;
