import { useLastPathname, useResponsive, useRouter } from '@/core/hooks';
import { LANG } from '@/core/i18n/src/page-lang';
import { SENCE } from '@/core/shared';
import { LOCAL_KEY, ThirdPartAuthProvider } from '@/core/store';
import { SESSION_KEY } from '@/core/store/src/session-storage';
import { transformScaleXRtl } from '@/core/utils';
import { MediaInfo } from '@/core/utils/src/media-info';
import { message } from '@/core/utils/src/message';
import React, { useCallback, useState } from 'react';
import css from 'styled-jsx/css';
import CommonIcon from '../common-icon';
import { Desktop } from '../responsive';
import { Tabs } from './components/tabs';
import { ThirdPartBtns } from './components/third-part-btns';
import { ROUTE_PATH_KEY } from './constants';
import { EmailForget } from './forget/email-forget';
import { PhoneForget } from './forget/phone-forget';
import ResetPwd from './forget/reset-pwd';
import { useSendCaptchaCode } from './hooks/useSendCaptcha';
import { EmailLogin } from './login/email-login';
import { LoginFooter } from './login/login-footer';
import { PhoneLogin } from './login/phone-login';
import { LoginQrCode } from './login/qrcode-login';
import { EmailRegister } from './register/email-register';
import { PhoneRegister } from './register/phone-register';
import VerifyRegisterCode from './register/verify-code';
import { SecurityVerify } from './security/security-verify';
import { store } from './store';
import { ThirdBind } from './third-part/third-bind';
import { ThirdRegister } from './third-part/third-register';
import { TAB_TYPE } from './types';

export const EntryPoint = () => {
  const { isMobile } = useResponsive();
  const lastPath = useLastPathname();
  const { showForgetEntry } = store;
  const [showQrCodePanel, setShowQrCodePanel] = useState(false);
  const [registerType, setRegisterType] = useState<TAB_TYPE>(TAB_TYPE.EMAIL_REGISTER);
  const router = useRouter();
  const type =
    registerType === TAB_TYPE.EMAIL_REGISTER ? LOCAL_KEY.INPUT_REGISTER_EMAIL : LOCAL_KEY.INPUT_REGISTER_PHONE;
  const { countdown, getVerificationCode } = useSendCaptchaCode(type, SENCE.REGISTER);
  const [showVerifyCodePage, setShowVerifyCodePage] = useState(false);
  // 设置登录成功回跳页面
  const onLoginSuccess = useCallback(() => {
    message.success(LANG('登录成功'));
    const backUrl = sessionStorage[SESSION_KEY.LOGIN_REDIRECT] || '';
    const ignorePathname = [ROUTE_PATH_KEY.LOGIN, ROUTE_PATH_KEY.FORGET, ROUTE_PATH_KEY.REGISTER, 'verify'];
    var isIgnored = ignorePathname.some((item) => backUrl.includes(item));
    if (isIgnored || !backUrl) {
      router.push('/');
      return;
    }
    if (backUrl.includes('p2p')) {
      router.push('/p2p/plaza-advertise-list');
    } else {
      window.location.href = backUrl; // 自带locale
    }
  }, []);
  const HEADER_TITLE_MAP: { [key: string]: string } = {
    [ROUTE_PATH_KEY.LOGIN]: LANG('登陆 BYDFI'),
    [ROUTE_PATH_KEY.REGISTER]: LANG('加入 BYDFI'),
    [ROUTE_PATH_KEY.INVITE]: LANG('加入 BYDFI'),
    [ROUTE_PATH_KEY.FORGET]: LANG('忘记密码'),
    [ROUTE_PATH_KEY.THIRD_REGISTER]: LANG('创建账号'),
    [ROUTE_PATH_KEY.THIRD_BIND]: LANG('关联账号'),
  };
  const singleTab = [ROUTE_PATH_KEY.THIRD_REGISTER, ROUTE_PATH_KEY.THIRD_BIND].includes(lastPath);

  const FOOTER_MAP: { [key: string]: React.ReactNode } = {
    [ROUTE_PATH_KEY.LOGIN]: <LoginFooter showQrCodePanel={showQrCodePanel} />,
  };
  const LOGIN_TABS = [
    {
      label: LANG('邮箱登录'),
      key: 'email',
      children: <EmailLogin onLoginSuccess={onLoginSuccess} />,
    },
    {
      label: LANG('手机登录'),
      key: 'phone',
      children: <PhoneLogin onLoginSuccess={onLoginSuccess} />,
    },
  ];
  if (isMobile) {
    LOGIN_TABS.splice(2, 1);
  }
  const FORGET_TABS = [
    {
      label: LANG('邮箱'),
      key: 'email',
      tips: LANG('重置登录密码后，24小时内禁止提币'),
      children: <EmailForget />,
    },
    {
      label: LANG('手机'),
      key: 'phone',
      tips: LANG('重置登录密码后，24小时内禁止提币'),
      children: <PhoneForget />,
    },
  ];
  const handleRegisterUser = async () => {
    const res = await getVerificationCode();
    if (res.code === 200) {
      const updatedQuery = { ...router.query };
      delete updatedQuery.locale;
      router.push({
        pathname: '/register',
        query: {
          ...updatedQuery,
        },
      });
      setShowVerifyCodePage(true);
    }
  };
  const REGISTER_TABS = [
    { label: LANG('邮箱注册'), key: 'email', children: <EmailRegister handleRegisterUser={handleRegisterUser} /> },
    { label: LANG('手机注册'), key: 'phone', children: <PhoneRegister handleRegisterUser={handleRegisterUser} /> },
  ];
  const TABS_MAP: any = {
    [ROUTE_PATH_KEY.LOGIN]: LOGIN_TABS,
    [ROUTE_PATH_KEY.FORGET]: FORGET_TABS,
    [ROUTE_PATH_KEY.REGISTER]: REGISTER_TABS,
    [ROUTE_PATH_KEY.INVITE]: REGISTER_TABS,
    [ROUTE_PATH_KEY.THIRD_REGISTER]: [{ children: <ThirdRegister onLoginSuccess={onLoginSuccess} /> }],
    [ROUTE_PATH_KEY.THIRD_BIND]: [{ children: <ThirdBind onLoginSuccess={onLoginSuccess} /> }],
  };
  if (!showForgetEntry && lastPath === 'forget') {
    return (
      <div className='account-wrapper'>
        <ResetPwd />
        <style jsx>{styles}</style>
      </div>
    );
  }
  const onQrCodeClick = () => {
    setShowQrCodePanel(!showQrCodePanel);
  };
  const onTabChange = (key: number) => {
    if (key === 0) {
      setRegisterType(TAB_TYPE.EMAIL_REGISTER);
    } else {
      setRegisterType(TAB_TYPE.PHONE_REGISTER);
    }
  };
  const renderTabContents = () => {
    if (showQrCodePanel) {
      return <LoginQrCode onLoginSuccess={onLoginSuccess} />;
    }
    return !singleTab ? <Tabs items={TABS_MAP[lastPath]} onTabChange={onTabChange} /> : TABS_MAP[lastPath][0].children;
  };
  return (
    <ThirdPartAuthProvider value={{ onLoginSuccess: onLoginSuccess }}>
      <div className='account-wrapper'>
        {showVerifyCodePage ? (
          <VerifyRegisterCode
            type={registerType}
            localType={type}
            countDown={countdown}
            onBack2Register={() => setShowVerifyCodePage(false)}
          />
        ) : (
          <div className='register-card'>
            {lastPath === ROUTE_PATH_KEY.LOGIN && (
              <Desktop>
                <CommonIcon
                  name={showQrCodePanel ? 'common-half-desktop-0' : 'common-half-qrcode-0'}
                  size={48}
                  className='qrcode-icon'
                  onClick={onQrCodeClick}
                  enableSkin
                  style={{ transform: transformScaleXRtl() }}
                />
              </Desktop>
            )}
            <h1 className='title'>{HEADER_TITLE_MAP[lastPath]}</h1>
            {renderTabContents()}
          </div>
        )}
        {ROUTE_PATH_KEY.LOGIN === lastPath && !showQrCodePanel && <ThirdPartBtns />}
        {FOOTER_MAP[lastPath]}
        <SecurityVerify />
        <style jsx>{styles}</style>
      </div>
    </ThirdPartAuthProvider>
  );
};
const styles = css`
  :global(.account-wrapper) {
    position: relative;
    :global(.qrcode-icon) {
      position: absolute;
      top: 28px;
      right: 24px;
      cursor: pointer;
    }
    @media ${MediaInfo.desktop} {
      width: 460px;
      height: 100%;
    }

    border-top-right-radius: 12px;
    padding: 24px;
    @media ${MediaInfo.tablet} {
      width: 100%;
      padding: 32px 24px;
      border-top-left-radius: 12px;
    }
    @media ${MediaInfo.mobile} {
      padding: 32px 16px;
    }
    .title {
      color: var(--theme-font-color-1);
      font-size: 24px;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      @media ${MediaInfo.mobileOrTablet} {
        text-align: center;
        justify-content: center;
        font-weight: 500;
        font-size: 24px;
      }
    }
  }
`;
