import { INPUT_TYPE } from '@/components/basic-input';
import { postCommonVisitApi } from '@/core/api';
import { renderLangContent } from '@/core/i18n';
import { LANG } from '@/core/i18n/src/page-lang';
import { clsx } from '@/core/utils/src/clsx';
import { getFParam, getReferParam } from '@/core/utils/src/get';
import { MediaInfo } from '@/core/utils/src/media-info';
import { Checkbox } from 'antd';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import css from 'styled-jsx/css';
import { BasicInput } from '../../basic-input';
import { store } from '../store';

export const InputInvitationCode = () => {
  const vipCodeParam = getReferParam('vipCode') || getReferParam('vipcode');
  const ruParam = getReferParam('ru') || '';
  const [showInput, setShowInput] = useState(false);
  const [ruValue, setRuValue] = useState(vipCodeParam || ruParam);
  const [showGreyCheckbox, setShowGreyCheckbox] = useState(false);
  const f = getFParam() || '';

  const handleShowInput = () => {
    setShowInput(!showInput);
  };
  const EmojiFilterRegex =
    /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g;
  const checkedStatus = !showGreyCheckbox;

  store.ru = ruValue?.replace(EmojiFilterRegex, '');
  store.checked = checkedStatus;

  const handleShowCheckbox = () => {
    setShowGreyCheckbox(checkedStatus);
  };

  const renderCheckbox = () => {
    return (
      <Checkbox checked={checkedStatus} onClick={handleShowCheckbox}>
        <p className='agree-item'>
          {renderLangContent(LANG('我已阅读并同意BYDFi的{permission1}和{permission2}'), {
            permission1: (
              <a href='https://support.bydfi.com/hc/en-us/articles/5691838199183-Terms-of-Use'>{LANG('用户协议')}</a>
            ),
            permission2: (
              <a href='https://support.bydfi.com/hc/en-us/articles/5691793917839-Privacy-Terms'>{LANG('隐私政策')}</a>
            ),
          })}
        </p>
      </Checkbox>
    );
  };
  const handleInputRuParam = (value: string) => {
    const filteredValue = value?.replace(EmojiFilterRegex, '');
    setRuValue(filteredValue);
  };
  useEffect(() => {
    if (ruParam) {
      postCommonVisitApi({ ru: ruParam, f });
    }
  }, [ruParam, f]);
  const arrowIcon = showInput ? '/static/images/common/triangle-up.svg' : '/static/images/common/triangle-down.svg';
  return (
    <div className='invitation-wrapper'>
      <p className={clsx('code-title')} onClick={handleShowInput}>
        <Image src={arrowIcon} width={14} height={14} alt='icon' className='icon' />
        {LANG('邀请码（选填）')}
      </p>
      {showInput ? (
        <BasicInput
          placeholder={LANG('请输入邀请码')}
          label={''}
          onInputChange={handleInputRuParam}
          hideErrorTips
          disabled={!!ruParam}
          value={ruValue}
          type={INPUT_TYPE.NORMAL_TEXT}
        />
      ) : null}
      <div className='check-box'>{renderCheckbox()}</div>
      <style jsx>{styles}</style>
    </div>
  );
};
const styles = css`
  .invitation-wrapper {
    .code-title {
      position: relative;
      display: inline-block;
      padding-right: 12px;
      cursor: pointer;
      display: flex;
      padding-bottom: 8px;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      color: var(--theme-font-color-1);
      :global(.icon) {
        margin-left: -4px;
      }
    }
    .check-box {
      display: flex;
      padding-bottom: 16px;
      padding-top: 16px;
      align-items: center;
      :global(.agree-item) {
        font-size: 12px;
        font-weight: 400;
        color: var(--spec-font-color-2);
        :global(a) {
          color: var(--skin-main-font-color);
          cursor: pointer;
        }
      }
      :global(..ant-checkbox) {
        @media ${MediaInfo.mobile} {
          align-self: flex-start;
        }
      }
    }
    :global(.basic-input-box .basic-input) {
      font-size: 18px !important;
      font-weight: 500;
    }
  }
`;
