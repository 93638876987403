import { EntryPoint } from '@/components/account';
import Image from '@/components/image';
import { useLastPathname } from '@/core/hooks';
import { clsx } from '@/core/utils/src/clsx';
import { MediaInfo } from '@/core/utils/src/media-info';
import dynamic from 'next/dynamic';
import css from 'styled-jsx/css';
import { ROUTE_PATH_KEY } from '../account/constants';
import { AffiliateHeader } from '../header/affiliate-header';
import { Desktop, DesktopOrTablet, Mobile, MobileOrTablet } from '../responsive';
import { UniversalLayout } from './universal';
const Sidebar = dynamic(() => import('./sidebar'), { ssr: false });
interface IProps {
  className?: string;
  children?: React.ReactNode;
  loginBoxClassName?: string;
  logoJumpId?: string;
}
export default function LoginCommonLayout(props: IProps) {
  const lastPathname = useLastPathname();
  const isRegister = lastPathname === ROUTE_PATH_KEY.REGISTER;
  const renderMainLoginContainer = () => {
    return (
      <div className={clsx('login-bg', props.className)}>
        <div className='auth-container'>
          {props.children ? (
            props.children
          ) : (
            <DesktopOrTablet>
              <div className='left-sidebar-container'>
                <Sidebar />
              </div>
            </DesktopOrTablet>
          )}
          <div
            className={clsx('right-form-container', props.loginBoxClassName)}
            style={{ minHeight: isRegister ? '706px' : '594px' }}
          >
            <Mobile forceInitRender={false}>
              <a href={`#${props.logoJumpId || ''}`} className='bonus-mobile'>
                <Image
                  src='/static/images/account/2888-bonus.svg'
                  width={142}
                  height={56}
                  loading='eager'
                  priority
                  alt='bonus'
                />
              </a>
            </Mobile>
            <EntryPoint />
          </div>
        </div>
        <style jsx>{styles}</style>
      </div>
    );
  };
  return (
    <>
      <Desktop forceInitRender={false}>
        <UniversalLayout hideFooter className='login-common' bgColor='var(--spec-background-color-1)'>
          {renderMainLoginContainer()}
        </UniversalLayout>
      </Desktop>
      <MobileOrTablet forceInitRender={false}>
        <AffiliateHeader />
        {renderMainLoginContainer()}
      </MobileOrTablet>
    </>
  );
}
const styles = css`
  :global(.login-common) {
    background-color: var(--spec-background-color-2);
  }
  :global(header) {
    @media ${MediaInfo.mobile} {
      background-color: var(--spec-background-color-2) !important;
    }
  }
  .login-bg {
    background: var(--spec-background-color-1);
    @media ${MediaInfo.mobile} {
      background-color: var(--spec-background-color-2);
    }
    display: flex;
    justify-content: center;
    align-items: center;
    @media ${MediaInfo.desktop} {
      min-height: calc(100vh - 64px);
    }
    height: 100%;
    @media ${MediaInfo.mobileOrTablet} {
      background-color: var(--spec-background-color-2);
      height: calc(100vh - 48px);
    }
    .auth-container {
      display: flex;
      padding: 0px;
      justify-content: center;
      align-items: stretch;
      height: auto;
      border-radius: 12px;
      @media ${MediaInfo.desktop} {
        margin-top: 48px;
        margin-bottom: 48px;
      }
      @media ${MediaInfo.tablet} {
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
      }
      @media ${MediaInfo.mobile} {
        width: 100%;
        min-height: 100% !important;
      }
    }
    .left-sidebar-container {
      width: 460px;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      :global(div) {
        height: 100%;
      }
      @media ${MediaInfo.tablet} {
        padding: 24px;
        width: 100%;
        border: none;
      }
    }
    .right-form-container {
      position: relative;
      background-color: var(--spec-background-color-2);
      height: 100%;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      @media ${MediaInfo.tablet} {
        width: 100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }
      @media ${MediaInfo.mobile} {
        width: 100%;
      }
      :global(.bonus-img) {
        border-radius: 8px;
        @media (${MediaInfo.mobile}) {
          display: none;
        }
      }
      :global(.bonus-mobile) {
        position: absolute;
        right: -14px;
        top: 70px;
        z-index: 10;
        width: 142px;
        height: auto;
      }
      :global(.basic-input-box) {
        background-color: var(--spec-background-color-3) !important;
      }
      :global(.basic-input) {
        font-size: 14px !important;
        font-weight: 500;
        background-color: var(--spec-background-color-3) !important;
      }
      :global(.input-pwd-wrapper) {
        margin-bottom: 16px;
      }
    }
    :global(.pc-bonus-img) {
      width: 600px;
      height: 586px;
    }
  }
`;
