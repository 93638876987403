import { VipPasswordInput } from '@/components/basic-input/vip-pwd-input';
import { InputEmail } from '../components/input-email';
import { InputInvitationCode } from '../components/invitation-code';
import { useBtnStatus } from '../hooks/useBtnStatus';
import { TAB_TYPE } from '../types';
import { RegisterFooter } from './register-footer';
import { RegisterNextBtn } from './register-next';

export const EmailRegister = ({ handleRegisterUser }: { handleRegisterUser?: () => void }) => {
  const [shouldDisableBtn] = useBtnStatus(TAB_TYPE.EMAIL_REGISTER);
  return (
    <>
      <InputEmail />
      <VipPasswordInput />
      <InputInvitationCode />
      <RegisterNextBtn
        shouldDisableBtn={shouldDisableBtn}
        handleRegisterUser={() => {
          if (shouldDisableBtn) return;
          handleRegisterUser?.();
        }}
      />
      <RegisterFooter />
    </>
  );
};
