import { Button } from '@/components/button';
import { LANG } from '@/core/i18n';
import css from 'styled-jsx/css';

export const RegisterNextBtn = ({
  shouldDisableBtn,
  handleRegisterUser,
}: {
  shouldDisableBtn: boolean;
  handleRegisterUser?: () => void;
}) => {
  return (
    <div className='register-next-btn'>
      <Button
        type='primary'
        className={shouldDisableBtn ? 'disabled' : ''}
        height={44}
        style={{ width: '100%' }}
        onClick={handleRegisterUser}
      >
        {LANG('注册')}
      </Button>
      <style jsx>{styles}</style>
    </div>
  );
};
const styles = css`
  .register-next-btn {
    :global(.common-button.disabled) {
      background-color: var(--spec-background-color-4);
      color: var(--spec-font-color-2);
    }
  }
`;
