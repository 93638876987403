import { useRouter } from '@/core/hooks';
import { MediaInfo } from '@/core/utils';
import css from 'styled-jsx/css';
import CommonIcon from '../common-icon';
import { Desktop, MobileOrTablet } from '../responsive';
import { TrActiveLink } from './components/active-link';
import GlobalIcon from './components/icon/global-config-icon';
import LogoIcon from './components/icon/logo-icon';

export const AffiliateHeader = () => {
  const router = useRouter();
  const onRouterBackClick = () => {
    router.back();
  };
  return (
    <>
      <Desktop>
        <div className='affiliate-header'>
          <div className='left'>
            <TrActiveLink aria-label='home' href='' style={{ paddingRight: 20, paddingLeft: 18 }}>
              <LogoIcon width='108' height='28' />
            </TrActiveLink>
          </div>
          <div className='right'>
            <GlobalIcon className='icon' />
          </div>
        </div>
        <style jsx>{styles}</style>
      </Desktop>
      <MobileOrTablet>
        <div className='mobile-affiliate-header' onClick={onRouterBackClick}>
          <CommonIcon name='common-prev-icon-0' size={20} />
        </div>
        <style jsx>{styles}</style>
      </MobileOrTablet>
    </>
  );
};

const styles = css`
  .affiliate-header {
    height: 68px;
    background-color: var(--spec-background-color-2);
    padding: 22px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media ${MediaInfo.mobileOrTablet} {
      display: none;
    }
    .left {
      :global(.header-item) {
        padding-left: 0px !important;
      }
      :global(svg) {
        color: var(--spec-font-color-1);
      }
    }
    .right {
      :global(.icon) {
        cursor: pointer;
      }
    }
  }
  :global(.mobile-affiliate-header) {
    background-color: var(--spec-background-color-2);
    border-bottom: 1px solid var(--spec-border-level-2);
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 14px 16px;
    cursor: pointer;
    @media ${MediaInfo.tablet} {
      height: 68px;
    }
  }
`;
