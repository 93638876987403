import { clsx, MediaInfo } from '@/core/utils';
import React, { useEffect, useRef, useState } from 'react';
import css from 'styled-jsx/css';
import { store } from '../store';

const DigitCodeInput = ({
  onInputCode,
  isRegisteredFailed,
}: {
  onInputCode: (code: string) => void;
  isRegisteredFailed: boolean;
}) => {
  const [values, setValues] = useState<string[]>(Array(6).fill(''));
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [inputFocus, setInputFocus] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleChange = (value: string, index: number) => {
    if (/^\d$/.test(value) || value === '') {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);
      if (value !== '' && index < inputRefs.current.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
      if (value !== '' && index === inputRefs.current.length - 1) {
        setCurrentIndex(-1);
      }
    }
  };

  // 处理粘贴事件
  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('Text');
    const digits = pastedData.replace(/\D/g, '').slice(0, 6); // 去掉非数字字符，并限制长度
    const newValues = Array(6).fill('');
    for (let i = 0; i < digits.length; i++) {
      newValues[i] = digits[i];
    }
    setValues(newValues);
  };

  useEffect(() => {
    const codeNumber = values.join('');
    store.smsCode = codeNumber;
    store.emailCode = codeNumber;
    onInputCode(codeNumber);
  }, [values]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && !values[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const onFocus = (idx: number) => {
    setCurrentIndex(idx);
    setInputFocus(true);
  };
  const onBlur = (idx: number) => {
    setInputFocus(false);
    setCurrentIndex(idx);
  };

  return (
    <div className={clsx('digital-input-container', isRegisteredFailed && 'error-border-input')}>
      {values.map((value, index) => (
        <input
          key={index}
          ref={(el) => (inputRefs.current[index] = el)}
          value={value}
          onFocus={() => onFocus(index)}
          onBlur={() => onBlur(index)}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste} // 添加 onPaste 事件
          maxLength={1}
          className={clsx('digit-input', inputFocus && currentIndex === index && 'input-focus')}
        />
      ))}
      <style jsx>{styles}</style>
    </div>
  );
};
const styles = css`
  .digital-input-container {
    display: flex;
    gap: 16px;
    @media ${MediaInfo.mobileOrTablet} {
      display: flex;
      justify-content: space-between; /* Distributes space evenly */
    }
    .digit-input {
      background-color: var(--spec-background-color-2);
      width: 57px;
      height: 52px;
      font-size: 28px;
      font-weight: 500;
      text-align: center;
      border-radius: 8px;
      border: 1px solid var(--spec-border-level-3);
      color: var(--spec-font-color-1);
      @media ${MediaInfo.tablet} {
        height: 82px;
      }
      @media ${MediaInfo.mobile} {
        height: 46px;
        width: 46px;
      }
      @media ${MediaInfo.mobileOrTablet} {
        flex: 1;
        width: 0;
      }
    }
    .input-focus {
      border-color: var(--skin-primary-color);
    }
  }
  .error-border-input {
    .digit-input {
      border: 1px solid var(--const-color-error);
    }
  }
`;
export default DigitCodeInput;
