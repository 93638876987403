import { Account, SENCE } from '@/core/shared/src/account';
import { getIdentity } from '@/core/utils/src/crypto';
import { getPlatform } from '@/core/utils/src/get';
import { message } from '@/core/utils/src/message';
import { removeCountryCode } from '@/core/utils/src/unknown';

import { EVENT_NAME, EVENT_TRACK, SENSORS_LOGIN } from '@/core/sensorsdata';
import { encryptAuthData } from '@/core/utils';
import { store } from '../store';

export const useLogin = (onLoginSuccess: () => void) => {
  const { email, password, countryCode, phone, curTab, trace } = store;
  const account = curTab === 1 ? countryCode + phone : email;
  const handleLogin = async () => {
    const cToken = await Account.verifyCode.getCToken();
    const loginVhash = getIdentity(32);
    store.loginVhash = loginVhash;
    const platform = getPlatform();
    const loginParam: any =
      curTab === 1
        ? {
            username: account,
            password: encryptAuthData(password),
            vHash: loginVhash,
            terminal: platform,
            trust: false,
            cToken,
            countryCode,
          }
        : {
            username: email,
            password: encryptAuthData(password),
            vHash: loginVhash,
            terminal: platform,
            trust: false,
            cToken,
          };
    if (trace) {
      loginParam.trace = trace;
    }
    EVENT_TRACK(EVENT_NAME.SubRegisterSign, {
      type: curTab === 0 ? '邮箱' : '手机号',
      is_register: false,
      invite_method: '无',
      precise_invite_channel: '',
      invite_code: '无',
    });
    const res = await Account.login(loginParam);
    if (res.code === 200) {
      SENSORS_LOGIN(res.data.uid);
      if (res.data.next) {
        const { email, phone, countryCode } = res.data;
        store.email = email || '';
        store.phone = removeCountryCode(countryCode, phone || '');
        store.countryCode = countryCode || '';
        const options = await Account.securityVerify.getSecurityOptions({
          vHash: loginVhash,
          account: account,
          sence: SENCE.LOGIN,
        });
        if (options.code === 200) {
          store.securityOptions = options.data;
          store.showVerifyModal = true;
          store.closeVerify = true;
        } else {
          message.error(options.message);
        }
      } else {
        onLoginSuccess();
        await Account.refreshUserInfo();
      }
    } else {
      message.error(res.message);
    }
  };
  return [handleLogin];
};
