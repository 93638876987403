import React from 'react';

import CommonIcon from '../common-icon';

export const CheckboxV2 = React.memo(
  ({
    className,
    checked,
    onClick = () => {},
    size = 12,
    ...props
  }: {
    className?: string;
    checked?: boolean;
    onClick?: Function;
    size?: number;
  }) => {
    return (
      <>
        <div
          className={`checkbox ${className} ${checked && 'checkbox-v2-active'}`}
          {...props}
          onClick={() => onClick()}
        >
          {checked && <CommonIcon name='common-checkbox-circle-active-0' width={size} height={size} enableSkin />}
        </div>
        <style jsx>
          {`
            .checkbox {
              width: ${size}px;
              height: ${size}px;
              cursor: pointer;
              border-radius: 50%;
              display: flex;
              border: 1px solid var(--spec-font-color-2);
              &.checkbox-v2-active {
                border: 0;
                border: none;
              }
            }
          `}
        </style>
      </>
    );
  }
);
export default CheckboxV2;
