import { BasicInput, INPUT_TYPE } from '@/components/basic-input';
import { AssetValueToggleIcon } from '@/components/common-icon';
import { LANG } from '@/core/i18n';
import { clsx, isVipRegisterPasswordValid } from '@/core/utils';
import { Dropdown, Menu } from 'antd';
import { useEffect, useState } from 'react';
import css from 'styled-jsx/css';
import { store } from '../account/store';
import CheckboxV2 from '../checkbox-v2';

export const VipPasswordInput = () => {
  const [password, setPassword] = useState('');
  const [eyeOpen, setEyeOpen] = useState(false);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    store.password = password;
  }, [password]);
  const conditions = [
    {
      id: 1,
      text: LANG('6-30个字符'),
      regex: /^.{6,30}$/,
    },
    { id: 2, text: LANG('至少包含一个大写字母'), regex: /[A-Z]/ },
    { id: 3, text: LANG('至少包含一个小写字母'), regex: /[a-z]/ },
    { id: 4, text: LANG('至少包含一个数字'), regex: /\d/ },
  ];
  // Check each condition
  const isConditionMet = (regex: RegExp) => regex.test(password);

  const menu = (
    <Menu className='condition-menu'>
      {conditions.map((condition) => (
        <Menu.Item key={condition.id}>
          {isConditionMet(condition.regex) ? <CheckboxV2 checked /> : <CheckboxV2 checked={false} />} {condition.text}
        </Menu.Item>
      ))}
      <p className='desc-tips'>
        {LANG('选填特殊符号支持{character}', { character: '!@#$%^&*?"\'()+,\\-./:;<>[\\]_`{|}~' })}
      </p>
    </Menu>
  );
  return (
    <div className={clsx('input-pwd-wrapper')}>
      <Dropdown overlay={menu} open={focused && !isVipRegisterPasswordValid(password)} placement='bottomCenter'>
        <BasicInput
          placeholder={LANG('请设置密码')}
          type={INPUT_TYPE.REGISTER_PASSWORD}
          value={password}
          label={LANG('登录密码')}
          showPwd={eyeOpen}
          onInputChange={(value) => setPassword(value)}
          onInputFocus={() => setFocused(true)}
          onInputBlur={() => setFocused(false)}
        />
      </Dropdown>
      <AssetValueToggleIcon className='eye-icon' show={eyeOpen} onClick={() => setEyeOpen(!eyeOpen)} />
      <style jsx>{styles}</style>
    </div>
  );
};
const styles = css`
  :global(.condition-menu) {
    border: 1px solid var(--spec-border-level-1);
    background-color: var(--spec-background-color-2) !important;
    :global(.ant-dropdown-menu-item) {
      font-size: 12px;
      padding: 6px 8px !important;
    }
    :global(.desc-tips) {
      color: var(--spec-font-color-2);
      padding: 6px 8px !important;
    }
    :global(.ant-dropdown-menu-item.ant-dropdown-menu-item-active) {
      background-color: unset !important;
      color: var(--spec-font-color-1) !important;
      cursor: default !important;
    }
    :global(.ant-dropdown-menu-item .ant-dropdown-menu-title-content) {
      display: flex;
      align-items: center;
      :global(.checkbox) {
        margin-right: 8px;
      }
    }
  }

  .input-pwd-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 24px;
    .login-pwd-label {
      line-height: 16px;
      font-size: 14px;
      font-weight: bold;
      color: #232e34;
    }
    :global(.eye-icon) {
      position: absolute;
      right: 15px;
      top: 42px;
      cursor: pointer;
    }
    :global(.basic-input-container) {
      :global(.basic-input) {
        margin-right: 40px;
      }
    }
    .input-pwd-box {
      padding-right: 0;
      .small {
        font-size: 12px;
      }
    }
  }
`;
