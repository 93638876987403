import { TrLink } from '@/core/i18n/src/components/tr-link';
import { LANG } from '@/core/i18n/src/page-lang';
import { ThirdPartBtns } from '../components/third-part-btns';
export const RegisterFooter = () => {
  return (
    <>
      <ThirdPartBtns />
      <p className='register-footer'>
        {LANG('已经是BYDFI用户？')}
        <TrLink href='/login'>&nbsp;&nbsp;{LANG('登录')}</TrLink>
        <style jsx>
          {`
            .register-footer {
              text-align: left;
              display: block;
              font-size: 14px;
              font-weight: 400;
              color: var(--spec-font-color-2);
              padding-top: 14px;
              :global(a) {
                font-size: 14px;
                font-weight: 700;
                color: var(--skin-main-font-color);
              }
            }
          `}
        </style>
      </p>
    </>
  );
};
